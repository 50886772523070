import { ITemplate, IUser } from '@alberta/konexi-shared';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTabChangeEvent } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Config } from 'src/app/shared/models/config.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConfigService } from 'src/app/shared/services/config.service';

import { DetailDrawerComponent } from '../../shared/layout/detail-drawer/detail-drawer.component';
import { Match } from '../../shared/models/match.model';
import { Report } from '../../shared/models/report.model';
import { Template } from '../../shared/models/template.model';
import { TemplateViewModel } from '../../shared/models/template.view.model';
import { MatchService } from '../../shared/services/match.service';
import { ReportService } from '../../shared/services/report.service';
import { CreateEditTemplateDialogComponent } from '../create-edit-template-dialog/create-edit-template-dialog.component';
import { TemplateDetailComponent } from '../template-detail/template-detail.component';
import { TemplateService } from '../template.service';

interface TableConfig {
  name: string;
  columns: string[];
  entityTypes: string[];
}

@Component({
  selector: 'app-template',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: true }) drawer: DetailDrawerComponent;
  @ViewChild('detail', { static: true })
  templateDetail: TemplateDetailComponent;

  private _dataSource$ = new Subject<Template[]>();
  public dataSource = this._dataSource$.asObservable();
  public selectedTemplate: Template;
  public tableConfig: TableConfig[];
  public currentTab: TableConfig;

  public showSpinner: boolean;
  public filter: string;
  public drawerOpen: boolean;
  public hasIntegratedCare: Boolean;
  public isResMed: Boolean;

  private _routeSubscription: Subscription;
  private _allTemplates: Template[];
  private _showArchived: boolean;
  private _user: IUser;

  constructor(
    private _templateService: TemplateService,
    private _reportService: ReportService,
    private _matchService: MatchService,
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _authService: AuthService,
    private configService: ConfigService
  ) {}

  async ngOnInit() {
    this._routeSubscription = this._route.parent.data.subscribe(async () => {
      const configs = (await this.configService.find()) as Config[];
      this.hasIntegratedCare = Boolean(configs[0].showIntegratedCare);
      if (configs[0]) {
        this.isResMed = Boolean(configs[0].showResmedTenant);
      }
      this._user = this._authService.currentUser;
      this.initTableConfig();
      this.loadTemplates();
    });
  }

  ngOnDestroy() {
    this._routeSubscription.unsubscribe();
  }

  public drawerClosing(): void {
    this.drawerOpen = false;
  }

  public async tabChanged(event: MatTabChangeEvent): Promise<void> {
    this.showSpinner = true;
    this.currentTab = this.tableConfig[event.index];
    await this.queryData();
    this.showSpinner = false;
  }

  public select(template: Template): void {
    this.openDrawer(0, template);
  }

  public openMatch(ev: Event, template: Template): void {
    ev.stopPropagation();
    this.openDrawer(1, template);
  }

  public openReport(ev: Event, template: Template): void {
    ev.stopPropagation();
    this.openDrawer(2, template);
  }

  public archivedToggled(archived: boolean, template: ITemplate): void {
    template.archived = archived;
    this.updateDataSource();
  }

  public addTempleteIfNotInArray(newTemplate: TemplateViewModel): void {
    const index = this._allTemplates.findIndex((template) => template._id === newTemplate._id);
    if (index === -1) {
      this._allTemplates.push(newTemplate);
    }
  }

  public add(): void {
    this._dialog
      .open(CreateEditTemplateDialogComponent, {
        width: '400px',
        maxHeight: '800px',
        data: { entityTypes: this.currentTab.entityTypes },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.update(result._id);
          this.select(result);
        }
      });
  }

  public toggleArchived(): void {
    this._showArchived = !this._showArchived;
    this.updateDataSource();
  }

  public async update(templateId: string): Promise<void> {
    if (!templateId) {
      this._dataSource$.next([]);
      this.loadTemplates();
      return;
    }

    this.showSpinner = true;
    const template = (await this._templateService.get(templateId, null, {
      noCaching: true,
    })) as TemplateViewModel;
    try {
      const report = await this._reportService.get(templateId, null, {
        noCaching: true,
      });
      template.report = report;
    } catch {}

    try {
      const match = await this._matchService.get(templateId, null, {
        noCaching: true,
      });
      template.match = match;
    } catch {}
    this.addTempleteIfNotInArray(template);
    this._allTemplates = this._allTemplates
      .map((m: TemplateViewModel) => (m._id === template._id ? template : m))
      .sort((a, b) => a.title.localeCompare(b.title));
    this.updateDataSource();
    this.showSpinner = false;
  }

  private async loadTemplates(): Promise<void> {
    this.showSpinner = true;
    await this.queryData();
    this.showSpinner = false;
  }

  private async queryData(): Promise<void> {
    this._dataSource$.next([]);
    const query: {
      entityTypes?: { $in: string[] };
    } = {};
    if (this.currentTab.entityTypes && this.currentTab.entityTypes.length) {
      query.entityTypes = { $in: this.currentTab.entityTypes };
    }
    this._allTemplates = (await this._templateService.find({
      query,
    })) as TemplateViewModel[];
    const reports = (await this._reportService.find({ query: { $select: ['_id'] } }, { noCaching: true })) as Report[];
    const matches = (await this._matchService.find({ query: { $select: ['_id'] } }, { noCaching: true })) as Match[];
    this._allTemplates = this._allTemplates
      .map((template: TemplateViewModel) => {
        template.report = reports.find((f) => f._id === template._id);
        template.match = matches.find((f) => f._id === template._id);
        return template;
      })
      .sort((a, b) => a.title.localeCompare(b.title));
    this.updateDataSource();
  }

  private openDrawer(index: number, template: Template): void {
    this.templateDetail.tabs.selectedIndex = index;
    setTimeout(() => {
      this.selectedTemplate = { ...template };
      this.drawer.open();
    }, 0);
  }

  private updateDataSource(): void {
    const templates = this._showArchived ? this._allTemplates : this._allTemplates.filter((f) => !f.archived);
    this._dataSource$.next(templates);
  }

  private initTableConfig() {
    this.tableConfig = [];

    this.tableConfig.push({
      name: 'Patient',
      columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
      entityTypes: ['patient'],
    });
    this.tableConfig.push({
      name: 'CRM Formulare',
      columns: ['status', 'title', 'entityTypes', 'groupingText', 'updatedAt', 'templateTag', 'matchReport', 'more'],
      entityTypes: ['nursingHome', 'doctor', 'nursingService', 'hospital', 'pharmacy'],
    });
    if (this.hasIntegratedCare) {
      this.tableConfig.push({
        name: 'Versorgungsdokumente',
        columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
        entityTypes: ['integratedCare'],
      });
      this.tableConfig.push({
        name: 'Bestellungen',
        columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
        entityTypes: ['singleOrder'],
      });
      this.tableConfig.push({
        name: 'Direktbestellungen',
        columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
        entityTypes: ['directOrder'],
      });
      this.tableConfig.push({
        name: 'Retoure',
        columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
        entityTypes: ['returnDelivery'],
      });
      if (this.isResMed) {
        this.tableConfig.push({
          name: 'Versorgungen therapieübergreifend',
          columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
          entityTypes: ['crossTherapy'],
        });
      }
    }

    if (this._user && (this._user.email.includes('resmed') || this._user.email.includes('it-labs'))) {
      this.tableConfig.forEach((item) => item.columns.unshift('sendEmailOnComplete'));
    }
    this.currentTab = this.tableConfig[0];
  }
}
