import * as tslib_1 from "tslib";
import { OnChanges } from '@angular/core';
import { AlbertaInstitutionsMatchFields, AlbertaPatientsMatchFields, } from '../../../shared/services/alberta-match-fields.service';
export class TemplateMatchComponent {
    constructor(_matchService) {
        this._matchService = _matchService;
        this.albertaMatchFields = [];
    }
    ngOnChanges() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.matchFields = undefined;
            this.templateNodes = undefined;
            if ((this.template && this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'patient') ||
                (this.template && this.template.entityTypes.length === 1 && this.template.entityTypes[0] === 'crossTherapy')) {
                this.albertaMatchFields = AlbertaPatientsMatchFields;
            }
            else {
                this.albertaMatchFields = AlbertaInstitutionsMatchFields;
            }
            this.albertaMatchFields.sort((a, b) => a.title.localeCompare(b.title));
            if (this.template) {
                this.showSpinner = true;
                this.templateNodes = this.buildTemplateNodesArray();
                if (this.template.match) {
                    const match = yield this._matchService.get(this.template._id);
                    if (match) {
                        this.matchFields = match.fields;
                    }
                }
                this.showSpinner = false;
            }
        });
    }
    compareFn(c1, c2) {
        return c1 && c2 ? c1._id === c2._id : c1 === c2;
    }
    addMatchField() {
        this.matchFields = this.matchFields || [];
        this.matchFields.push({ swodoc: undefined, alberta: undefined });
    }
    removeMatchField(field) {
        this.matchFields = this.matchFields.filter((f) => f !== field);
    }
    itemDisabled(id) {
        if (this.matchFields && this.matchFields.length > 0) {
            return this.matchFields.find((f) => f.swodoc === id) !== undefined;
        }
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.matchFields) {
                if (this.matchFields.length === 0) {
                    return yield this._matchService.remove(this.template._id);
                }
                if (this.template.match) {
                    yield this._matchService.patch(this.template._id, {
                        fields: this.matchFields,
                    });
                }
                else {
                    yield this._matchService.create({
                        _id: this.template._id,
                        fields: this.matchFields,
                    });
                }
            }
        });
    }
    buildTemplateNodesArray() {
        const templateNode = { template: this.template.title, sections: [] };
        this.template.templateNodes.forEach((section) => {
            const templateNodeSection = { title: section.title, nodes: [] };
            this.buildTemplateNodesArrayRec(section, templateNodeSection);
            templateNode.sections.push(templateNodeSection);
        });
        return templateNode;
    }
    buildTemplateNodesArrayRec(node, templateNodeSection) {
        if (node.children && node.children !== null) {
            this.buildTemplateNodesArrayRec(node.children, templateNodeSection);
        }
        else {
            node.forEach((child) => {
                if (child.nodeType_Id === 'text' || child.nodeType_Id === 'textarea' || child.nodeType_Id === 'date') {
                    templateNodeSection.nodes.push({
                        _id: child._id,
                        title: child.title !== '' ? child.title : '- no name -',
                        name: child._name,
                        type: child.nodeType_Id,
                    });
                }
                if (child.children && child.children !== null && child.children.length) {
                    this.buildTemplateNodesArrayRec(child.children, templateNodeSection);
                }
            });
        }
    }
}
