import * as tslib_1 from "tslib";
import { OnChanges, OnInit } from '@angular/core';
export class TemplateReportComponent {
    constructor(_reportService, _createAuditService, _auditReportService) {
        this._reportService = _reportService;
        this._createAuditService = _createAuditService;
        this._auditReportService = _auditReportService;
    }
    ngOnInit() {
        Stimulsoft.Base.StiLicense.key =
            '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHlOxAEeMTQ4mbxbuJwNuq1ugniSMxbCAn2oWrbtFkH02gixUM' +
                'fN8LDCa3NSZkWI/dK+f28zJq+YDGAvPKdnkoM33frMyvOCzIR+c2sdHB+Oa4jwuy8KhCOY+JGBMEuTN8+MJq+n1DmF' +
                '916AePNXeKjxX8/ipiSnGsH/hZc0gSsFHyZRiopm1CH+RVN70R7s2zsp+SC8HnVEvdgpgidr9YKRsXfzEssoxLSI4k' +
                'iibGZq4D74vyQXslCFnI7+LapltWIsTqjg4wDlyTamjZzwV5lvr8RL/dL4GdDwUVm2u0XGcKlxATB7xCT0zdM12rEn' +
                'l+8fc/hZJ6KAaEaK4OqKx2Yb3LVwFCQpkQgb8ygVwu4FJgFMWcFwGLozXyjfmt89VpLJEaIGTbp066umAvZflfFD2c' +
                'hGOds1iUmXq0X/fIPeMIexk/QGhxh5d8+XU2/y+SAZahIjbJl/x4OZYJwtUQcJIN9/rE2am4DmLo8dgN1gRQhHVRG6' +
                'T7naPWXt9gwxm6U/zQfz5IMV2iv2SM41L/HL';
        const options = new Stimulsoft.Designer.StiDesignerOptions();
        options.appearance.fullScreenMode = true;
        options.viewerOptions.appearance.reportDisplayMode = 2;
        const toolbarOptions = new Stimulsoft.Designer.StiToolbarOptions();
        toolbarOptions.showSaveButton = false;
        options.toolbar = toolbarOptions;
        this.designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
    }
    save(currentTab) {
        if (this.designer.report == null) {
            return;
        }
        if (this.designer.report.isModified === false) {
            return;
        }
        // if (currentTab === 2 && this.designer.report.getComponentsCount() < 2) {
        //   Stimulsoft.System.StiError.showError(
        //     'Du versuchst einen leeren Bericht zu speichern. Bericht wird nicht gespeichert!',
        //     true
        //   );
        //   return;
        // }
        const jsonStr = this.designer.report.saveToJsonString();
        if (this.template.report) {
            this._reportService.patch(this.template._id, { report: jsonStr });
        }
        else {
            this._reportService.create({ _id: this.template._id, report: jsonStr });
            this.template.report = true;
        }
        this.designer.report.isModified = false;
    }
    ngOnChanges() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.template) {
                // init report with undefined so we dont save accidentally an empty report
                this.designer.report = undefined;
                // this.showSpinner = true;
                const report = new Stimulsoft.Report.StiReport();
                if (this.template.report) {
                    const reportEntity = yield this._reportService.get(this.template._id);
                    if (reportEntity) {
                        report.load(reportEntity.report);
                    }
                }
                const data = yield this.prepareData();
                const dataSet = new Stimulsoft.System.Data.DataSet('swodoc');
                dataSet.readJson(data);
                report.regData(dataSet.dataSetName, '', dataSet);
                report.dictionary.synchronize();
                this.designer.report = report;
                // this.showSpinner = false;
            }
        });
    }
    // gets called by template-detail.component.ts
    renderDesigner() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this._designerRendered) {
                return;
            }
            this.showSpinner = true;
            this.designer.renderHtml(this._designer.nativeElement);
            this._designerRendered = true;
            this.showSpinner = false;
        });
    }
    prepareData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const audit = yield this._createAuditService.createAudit(this.template);
            return this._auditReportService.prepareDataForReport(audit);
        });
    }
}
