import * as tslib_1 from "tslib";
import { DocumentGenerator } from '@alberta/swodoc-document-generator';
import { EmptyAssetLoader } from './asset-loader';
import { KeyValueGenerator } from './key-value-Generator';
import * as i0 from "@angular/core";
export class AuditReportService {
    prepareDataForReport(audit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const documentGenerator = new DocumentGenerator(new EmptyAssetLoader(), new KeyValueGenerator());
            const data = yield documentGenerator.generate(audit, { allConitionsTrue: true });
            return data;
        });
    }
}
AuditReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuditReportService_Factory() { return new AuditReportService(); }, token: AuditReportService, providedIn: "root" });
