import { IAudit, IAuditNode, IPermissions, TemplateType } from '@alberta/konexi-shared';
import { MongoModel } from 'src/app/shared/models/_mongo.model';

export class Audit extends MongoModel implements IAudit {
  optimizedFormularValidation: boolean;
  hash: {
    [nodeId: string]: string;
  };
  additionalInfo: string;
  emailAddresses: string[];
  permissions: IPermissions;
  patientId: string;
  type: TemplateType;
  therapyId: number;
  therapyTypeId: number;
  woundLocation: string;
  regionId: string;
  completed: boolean;
  templateId: string;
  displayType = 0;

  auditNodes: IAuditNode[] = [];

  createdUtc: string;
  updatedUtc: string;

  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  archived: boolean;
}
