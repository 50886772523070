import { IAudit } from '@alberta/konexi-shared';
import { AssetLoadable, ImageItem } from '@alberta/swodoc-document-generator';

import { TreeArray } from '../tree-array';

export class EmptyAssetLoader implements AssetLoadable {
  async load(audit: IAudit): Promise<ImageItem[]> {
    const imagesItems = TreeArray.filterRec(
      audit.auditNodes,
      (f) => f.nodeType_Id === 'signature' || f.nodeType_Id === 'image' || f.nodeType_Id === 'picture'
    );
    return imagesItems.map((item) => {
      return {
        _id: item.nodeType_Id === 'picture' ? item.options : 'mockId',
        uploadDate: new Date().toISOString(),
        data: 'mock-data',
        metadata: {
          auditNodeId: item.id,
        },
      } as ImageItem;
    });
  }
}
