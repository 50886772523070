import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CreateEditTemplateDialogComponent } from '../create-edit-template-dialog/create-edit-template-dialog.component';
export class TemplateListComponent {
    constructor(_templateService, _reportService, _matchService, _route, _dialog, _authService, configService) {
        this._templateService = _templateService;
        this._reportService = _reportService;
        this._matchService = _matchService;
        this._route = _route;
        this._dialog = _dialog;
        this._authService = _authService;
        this.configService = configService;
        this._dataSource$ = new Subject();
        this.dataSource = this._dataSource$.asObservable();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this._routeSubscription = this._route.parent.data.subscribe(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const configs = (yield this.configService.find());
                this.hasIntegratedCare = Boolean(configs[0].showIntegratedCare);
                if (configs[0]) {
                    this.isResMed = Boolean(configs[0].showResmedTenant);
                }
                this._user = this._authService.currentUser;
                this.initTableConfig();
                this.loadTemplates();
            }));
        });
    }
    ngOnDestroy() {
        this._routeSubscription.unsubscribe();
    }
    drawerClosing() {
        this.drawerOpen = false;
    }
    tabChanged(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.showSpinner = true;
            this.currentTab = this.tableConfig[event.index];
            yield this.queryData();
            this.showSpinner = false;
        });
    }
    select(template) {
        this.openDrawer(0, template);
    }
    openMatch(ev, template) {
        ev.stopPropagation();
        this.openDrawer(1, template);
    }
    openReport(ev, template) {
        ev.stopPropagation();
        this.openDrawer(2, template);
    }
    archivedToggled(archived, template) {
        template.archived = archived;
        this.updateDataSource();
    }
    addTempleteIfNotInArray(newTemplate) {
        const index = this._allTemplates.findIndex((template) => template._id === newTemplate._id);
        if (index === -1) {
            this._allTemplates.push(newTemplate);
        }
    }
    add() {
        this._dialog
            .open(CreateEditTemplateDialogComponent, {
            width: '400px',
            maxHeight: '800px',
            data: { entityTypes: this.currentTab.entityTypes },
        })
            .afterClosed()
            .subscribe((result) => {
            if (result) {
                this.update(result._id);
                this.select(result);
            }
        });
    }
    toggleArchived() {
        this._showArchived = !this._showArchived;
        this.updateDataSource();
    }
    update(templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!templateId) {
                this._dataSource$.next([]);
                this.loadTemplates();
                return;
            }
            this.showSpinner = true;
            const template = (yield this._templateService.get(templateId, null, {
                noCaching: true,
            }));
            try {
                const report = yield this._reportService.get(templateId, null, {
                    noCaching: true,
                });
                template.report = report;
            }
            catch (_a) { }
            try {
                const match = yield this._matchService.get(templateId, null, {
                    noCaching: true,
                });
                template.match = match;
            }
            catch (_b) { }
            this.addTempleteIfNotInArray(template);
            this._allTemplates = this._allTemplates
                .map((m) => (m._id === template._id ? template : m))
                .sort((a, b) => a.title.localeCompare(b.title));
            this.updateDataSource();
            this.showSpinner = false;
        });
    }
    loadTemplates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.showSpinner = true;
            yield this.queryData();
            this.showSpinner = false;
        });
    }
    queryData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this._dataSource$.next([]);
            const query = {};
            if (this.currentTab.entityTypes && this.currentTab.entityTypes.length) {
                query.entityTypes = { $in: this.currentTab.entityTypes };
            }
            this._allTemplates = (yield this._templateService.find({
                query,
            }));
            const reports = (yield this._reportService.find({ query: { $select: ['_id'] } }, { noCaching: true }));
            const matches = (yield this._matchService.find({ query: { $select: ['_id'] } }, { noCaching: true }));
            this._allTemplates = this._allTemplates
                .map((template) => {
                template.report = reports.find((f) => f._id === template._id);
                template.match = matches.find((f) => f._id === template._id);
                return template;
            })
                .sort((a, b) => a.title.localeCompare(b.title));
            this.updateDataSource();
        });
    }
    openDrawer(index, template) {
        this.templateDetail.tabs.selectedIndex = index;
        setTimeout(() => {
            this.selectedTemplate = Object.assign({}, template);
            this.drawer.open();
        }, 0);
    }
    updateDataSource() {
        const templates = this._showArchived ? this._allTemplates : this._allTemplates.filter((f) => !f.archived);
        this._dataSource$.next(templates);
    }
    initTableConfig() {
        this.tableConfig = [];
        this.tableConfig.push({
            name: 'Patient',
            columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
            entityTypes: ['patient'],
        });
        this.tableConfig.push({
            name: 'CRM Formulare',
            columns: ['status', 'title', 'entityTypes', 'groupingText', 'updatedAt', 'templateTag', 'matchReport', 'more'],
            entityTypes: ['nursingHome', 'doctor', 'nursingService', 'hospital', 'pharmacy'],
        });
        if (this.hasIntegratedCare) {
            this.tableConfig.push({
                name: 'Versorgungsdokumente',
                columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
                entityTypes: ['integratedCare'],
            });
            this.tableConfig.push({
                name: 'Bestellungen',
                columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
                entityTypes: ['singleOrder'],
            });
            this.tableConfig.push({
                name: 'Direktbestellungen',
                columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
                entityTypes: ['directOrder'],
            });
            this.tableConfig.push({
                name: 'Retoure',
                columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
                entityTypes: ['returnDelivery'],
            });
            if (this.isResMed) {
                this.tableConfig.push({
                    name: 'Versorgungen therapieübergreifend',
                    columns: ['status', 'title', 'therapy', 'updatedAt', 'templateTag', 'version', 'matchReport', 'more'],
                    entityTypes: ['crossTherapy'],
                });
            }
        }
        if (this._user && (this._user.email.includes('resmed') || this._user.email.includes('it-labs'))) {
            this.tableConfig.forEach((item) => item.columns.unshift('sendEmailOnComplete'));
        }
        this.currentTab = this.tableConfig[0];
    }
}
