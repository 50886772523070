import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
export class AuthService {
    constructor(feathers) {
        this._isAuthenticatedSubject = new ReplaySubject(1);
        this.isAuthenticated = this._isAuthenticatedSubject.asObservable();
        this._app = feathers.app;
        this._authenticate();
    }
    _authenticate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this._app.reAuthenticate(true);
                this.currentUser = result.user;
                this._isAuthenticatedSubject.next(true);
            }
            catch (e) {
                this._isAuthenticatedSubject.next(false);
            }
        });
    }
    login(username, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const options = {
                strategy: 'fusionAuth',
                email: username.toLowerCase(),
                password,
                applicationId: '5e9e8812-b866-48d2-9b15-8a96fdb92473',
            };
            const result = yield this._app.authenticate(options);
            this.currentUser = result.user;
            this._isAuthenticatedSubject.next(true);
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._app.logout();
            this._isAuthenticatedSubject.next(false);
        });
    }
    getToken() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this._app.authentication.getAccessToken();
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.FeathersService)); }, token: AuthService, providedIn: "root" });
